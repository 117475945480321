import React, {useMemo, useState, useCallback, useEffect} from "react";
import {
  Table,
  Modal,
  Form,
  Row,
  Col,
  Space,
  Tooltip,
  Button,
  Tabs,
  Card,
  DatePicker,
  Statistic,
  Tag,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  FileDoneOutlined,
  SearchOutlined,
  ClearOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  ReconciliationOutlined,
  HistoryOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {AiFillFolderOpen} from "react-icons/ai";
import {useApp, useAuth, useModels, useQuery} from "../../hooks";
import {ActionsButton} from "../../components";
import {SimpleTableLayout} from "../../components/layouts";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {meses} from "../../utilities";

const {TabPane} = Tabs;
const {REACT_APP_API_URL: baseUrl} = process.env;

const FondoLegislativo = () => {
  const titulo = "Gestión Legislativa";
  const history = useHistory();
  const {token} = useApp();
  const {user} = useAuth();
  const query = useQuery();
  const opt = query.get("opt");
  const t = query.get("t");
  const model = "fondo-legislativo";
  const ordenar = "creado-desc";

  const [tab, setTab] = useState(t || "1");
  const [selectedPageSize, setSelectedPageSize] = useState(20);
  const [buscar, setBuscar] = useState(undefined);
  const [inicio, setInicio] = useState(undefined);
  const [requestFolio, setRequestFolio] = useState({});
  const [esAprobador, setEsAprobador] = useState(false);

  const {RangePicker} = DatePicker;
  const [fin, setFin] = useState(undefined);
  const _baseUrl = baseUrl.replace("v1/", "");
  const [form] = Form.useForm();
  const [formFolio] = Form.useForm();

  const limite = 10;

  const extraParamsMemo = useMemo(() => {
    let params = {
      estatus:
        tab === "1"
          ? ""
          : tab === "2"
            ? "NUEVO"
            : tab === "5"
              ? "NUEVO"
              : tab === "6"
                ? "NUEVO"
                : tab === "3"
                  ? "APROBADO"
                  : tab === "4"
                    ? "RECHAZADO"
                    : "",
    };

    if (tab === "5") params.tipo = "FGL";
    if (tab === "6") params.tipo = "51";

    if (buscar && buscar.trim() !== "") {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio.format("YYYY-MM-DD");
      params.fin = fin.format("YYYY-MM-DD");
    }

    if (opt) {
      params.opt = opt;
    }

    return params;
  }, [buscar, fin, inicio, tab, opt]);

  const requestFolioParams = useMemo(
    () => ({
      name: "folio",
      extraParams: {
        legislatura: 63,
      },
    }),
    []
  );

  const [
    FondoLegislativos,
    FondoLegislativosLoading,
    ,
    FondoLegislativosPage,
    FondoLegislativosRefresh,
  ] = useModels({
    name: model,
    ordenar: ordenar,
    extraParams: extraParamsMemo,
    expand:
      "creador, modificador, aprueba, archivos, comentarios, comentarios.usuario, solicitudes",
  });

  const [folio] = useModels(requestFolio);

  useEffect(() => {
    setRequestFolio(requestFolioParams);
  }, [requestFolioParams]);

  const [dashboard] = useModels({
    name: model + "/dashboard",
    extraParams: extraParamsMemo,
  });

  const tagColores = {
    NUEVO: "",
    APROBADO: "#006843",
    RECHAZADO: "#f00821",
  };

  const defaultText = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {prop}
      </span>
    ) : (
      <span style={{color: "#c7c3c3"}}>---</span>
    );
  };

  const defaultMonto = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        $
        {new Intl.NumberFormat("es-MX", {minimumFractionDigits: 2}).format(
          prop
        )}
      </span>
    ) : (
      <span style={{color: "#c7c3c3"}}>---</span>
    );
  };

  const defaultTipo = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {prop === "FGL"
          ? "Asistencia Legislativa y Atención Ciudadana"
          : "Representación Legislativa"}
      </span>
    ) : (
      <span style={{color: "#c7c3c3"}}>---</span>
    );
  };

  const defaultSubTipo = (prop, item) => {
    let _props = prop?.split(",");

    let mensaje = _props?.map(
      (item) => item.charAt(0) + item.toLowerCase().slice(1) + " "
    );
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {mensaje}
      </span>
    ) : (
      <span style={{color: "#c7c3c3"}}>---</span>
    );
  };

  const defaultCreador = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {item?.creador?.nombreCompleto}
      </span>
    ) : (
      <span style={{color: "#c7c3c3"}}>---</span>
    );
  };

  const optionsButtons = (item) => {
    let _mostrar = false;

    if (user?.permisoExtra?.includes(155)) _mostrar = true;
    if (user?.permisoExtra?.includes(156)) _mostrar = true;
    if (item?.estatus !== "APROBADO") _mostrar = false;

    return [
      {
        name: "Detalles",
        icon: <EditOutlined/>,
        onClick: () =>
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`),
      },
      {
        name: "Formato Solicitud",
        icon: <PrinterOutlined/>,
        onClick: () =>
          window.open(
            `${_baseUrl}/pdf/${model}/por-autorizar?id=${item.idFondoLegislativo}&access-token=${token}`,
            "_blank"
          ),
      },
      _mostrar
        ? {
          name: "Formato Aprobación",
          icon: <PrinterOutlined/>,
          onClick: () =>
            window.open(
              `${_baseUrl}/pdf/${model}/autorizacion?id=${item.idFondoLegislativo}&access-token=${token}`,
              "_blank"
            ),
        }
        : {},
    ];
  };

  const onSetPageCallback = useCallback(
    async (page, size = limite) => {
      setSelectedPageSize(size);
      await FondoLegislativosRefresh(true, {
        ordenar: ordenar,
        pagina: page,
        limite: size,
      });
    },
    [FondoLegislativosRefresh]
  );

  const columnas = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          size="large"
          type="default"
          options={optionsButtons(item)}
        />
      ),
    },
    {
      title: "Fecha",
      dataIndex: "creado",
      key: "creado",
      render: (_, item) => (
        <span
          onClick={() => {
            history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
          }}
          className={"link-pointer"}
        >
          {moment(item.creado).parseZone().local().format("DD/MM/YYYY HH:mm")}
        </span>
      ),
    },
    user?.permisoExtra?.includes(155) || user?.permisoExtra?.includes(156)
      ? {
        title: "Diputado",
        dataIndex: "idCreador",
        key: "idCreador",
        render: defaultCreador,
      }
      : {},
    {
      title: "Folio",
      dataIndex: "folio",
      key: "folio",
      render: (item, row) => (
        <Tag
          color={tagColores[row?.estatus]}
          className={"link-pointer"}
          onClick={() => {
            history.push(`/${model}/detalle?id=${row?.idFondoLegislativo}`);
          }}
        >
          {item}
        </Tag>
      ),
    },
    {
      title: "Mes Solicitado",
      dataIndex: "mes",
      key: "mes",
      render: (item, row) => (
        <span
          className={"link-pointer"}
          onClick={() => {
            history.push(`/${model}/detalle?id=${row?.idFondoLegislativo}`);
          }}
        >
          {meses[item]?.label}
        </span>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      render: defaultTipo,
    },
    {
      title: "Concepto",
      dataIndex: "subTipo",
      key: "subTipo",
      render: defaultSubTipo,
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      render: defaultMonto,
    },
  ];

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/fondo-legislativo",
      icon: <FileDoneOutlined/>,
    },
  ];

  const tabsHeader = [
    {
      tab: (
        <span>
          <ReconciliationOutlined/>
          Principal
        </span>
      ),
      key: "1",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined/>
          En Proceso ({dashboard[0]?.nuevos})
        </span>
      ),
      key: "2",
    },
    {
      tab: (
        <span>
          {" "}
          <CheckCircleOutlined/>
          Autorizados ({dashboard[0]?.aprobados})
        </span>
      ),
      key: "3",
    },
    {
      tab: (
        <span>
          {" "}
          <CloseCircleOutlined/>
          Rechazados ({dashboard[0]?.rechazados})
        </span>
      ),
      key: "4",
    },
  ];

  const tabsHeaderPermisos = [
    {
      tab: (
        <span>
          <ReconciliationOutlined/>
          Principal
        </span>
      ),
      key: "1",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined/>
          Asistencia Legislativa y Atención Ciudadana ({dashboard[0]?.fgl})
        </span>
      ),
      key: "5",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined/>
          Representación Legislativa ({dashboard[0]?.dga})
        </span>
      ),
      key: "6",
    },
    {
      tab: (
        <span>
          {" "}
          <CheckCircleOutlined/>
          Autorizados ({dashboard[0]?.aprobados})
        </span>
      ),
      key: "3",
    },
    {
      tab: (
        <span>
          {" "}
          <CloseCircleOutlined/>
          Rechazados ({dashboard[0]?.rechazados})
        </span>
      ),
      key: "4",
    },
  ];

  const onPrintExcel = () => {
    const {rango, docTipo} = form.getFieldsValue(true);

    if (rango === undefined) {
      Modal.error({
        title: "elegir un rango de fechas",
        content: "Seleccione un rango de fechas",
      });
      return;
    }

    window.open(
      `${_baseUrl}/pdf/reportes/excel-${model}?` +
      `fi=${rango[0].format("YYYY-MM-DD")}&` +
      `ff=${rango[1].format("YYYY-MM-DD")}&` +
      (docTipo ? `t=${docTipo}&` : "") +
      `access-token=${token}`,
      "_blank"
    );
  };

  const SearchForm = () => {
    const onFinishButton = async (values) => {
      const {buscar, rango} = form.getFieldsValue(true);
      setBuscar("");
      setInicio(null);
      setFin(null);

      if (buscar && buscar.trim() !== "") {
        setBuscar(buscar.trim());
      }
      if (rango && rango.length > 0) {
        setInicio(moment(rango[0].format("YYYY-MM-DD") + " 00:00:00"));
        setFin(moment(rango[1].format("YYYY-MM-DD") + " 23:59:59"));
      }
    };

    return (
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={13} lg={13} xl={13}>
            <Form.Item label="Rango:" name="rango">
              <RangePicker
                disabled={FondoLegislativosLoading}
                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                placeholder={["FECHA INICIO", "FECHA LÍMITE"]}
                style={{width: "100%"}}
                defaultValue={[inicio, fin]}
                value={[inicio, fin]}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <Space justify="space-between" style={{marginTop: ".5rem"}}>
              <Tooltip title="Buscar">
                <Button
                  icon={<SearchOutlined/>}
                  style={{marginTop: "1.5rem"}}
                  shape="circle"
                  type="primary"
                  loading={FondoLegislativosLoading}
                  disabled={FondoLegislativosLoading}
                  onClick={onFinishButton}
                />
              </Tooltip>
              <Tooltip title="Limpiar">
                <Button
                  icon={<ClearOutlined/>}
                  style={{marginTop: "1.5rem"}}
                  shape="circle"
                  type="default"
                  disabled={FondoLegislativosLoading}
                  onClick={() => {
                    form.resetFields();
                    setBuscar("");
                    setInicio(null);
                    setFin(null);
                    FondoLegislativosRefresh();
                    if (t && opt) {
                      window.history.replaceState("", "", "/requisiciones");
                      window.location.reload();
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Imprimir Excel">
                <Button
                  icon={<FileExcelOutlined/>}
                  shape="circle"
                  onClick={onPrintExcel}
                  style={{
                    marginTop: "1.5rem",
                    background: "#1f6e43",
                    color: "#ffffff",
                  }}
                />
              </Tooltip>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4} offset={2}>
            <Form.Item label="&nbsp;">
              <Button
                block
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => history.push(`/fondo-legislativo/detalle`)}
              >
                Agregar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  const configPagination = useMemo(() => {
    const modelsPage = FondoLegislativosPage?.limite;

    if (modelsPage) {
      let size = selectedPageSize;

      return {
        total: modelsPage?.total,
        pageSize: modelsPage?.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} ${"Documentos"}.`,
        locale: {items_per_page: "página"},
        pageSizeOptions: [10, 20, 30].filter((val) => val <= modelsPage?.total),
        showSizeChanger: true,
      };
    }

    return null;
  }, [FondoLegislativosPage?.limite, onSetPageCallback, selectedPageSize, tab]);

  useEffect(() => {
    let _folio = folio[0] || {};
    if (_folio !== undefined) {
      formFolio.setFieldsValue(_folio);
    }
  }, [folio, formFolio]);

  useEffect(() => {
    if (user?.permisoExtra?.includes(156)) setEsAprobador(true);
    if (user?.permisoExtra?.includes(155)) setEsAprobador(true);
  }, [user]);

  return (
    <SimpleTableLayout
      title={titulo}
      withSearchButton={false}
      breadcrumbItems={breadcrumbItems}
      children={
        <>
          <SearchForm/>
          <Tabs
            defaultActiveKey={{tab} + ""}
            activeKey={tab}
            type="card"
            onChange={(e) => {
              setTab(e);
            }}
          >
            {(esAprobador ? tabsHeaderPermisos : tabsHeader).map((tap) => (
              <TabPane tab={tap.tab} key={tap.key}/>
            ))}
          </Tabs>
          <Row gutter={3} hidden={tab !== "1"}>
            <Col span={6}>
              <Card
                className="link-pointer"
                onClick={() => {
                  setTab("2");
                }}
              >
                <Statistic
                  title="En Proceso"
                  valueStyle={{
                    color: "#03a9f4",
                  }}
                  value={dashboard[0]?.nuevos}
                  prefix={<HistoryOutlined/>}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="link-pointer"
                onClick={() => {
                  setTab("3");
                }}
              >
                <Statistic
                  title="Autorizados"
                  valueStyle={{
                    color: "#1c5401",
                  }}
                  value={dashboard[0]?.aprobados}
                  prefix={<CheckCircleOutlined/>}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="link-pointer"
                onClick={() => {
                  setTab("4");
                }}
              >
                <Statistic
                  title="Rechazados"
                  valueStyle={{
                    color: "#ce0000",
                  }}
                  value={dashboard[0]?.rechazados}
                  prefix={<CloseCircleOutlined/>}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total"
                  valueStyle={{
                    color: "#cf1322",
                  }}
                  value={dashboard[0]?.todos}
                  prefix={<AiFillFolderOpen/>}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[3, 5]} hidden={tab === "1"}>
            <Table
              rowKey="idFondoLegislativo"
              pagination={configPagination}
              dataSource={FondoLegislativos}
              loading={FondoLegislativosLoading}
              columns={columnas}
              size="small"
              scroll={{x: 1000}}
              style={{width: "100%"}}
            />
          </Row>
        </>
      }
    />
  );
};

export default FondoLegislativo;
