import React, {useEffect, useMemo, useState} from 'react'
import {useModel, useQuery} from '../../../hooks'
import {
  EditOutlined,
  FileDoneOutlined,
  PlusCircleOutlined, ArrowLeftOutlined
} from "@ant-design/icons";
import {DefaultLayout} from '../../../components/layouts'
import Formulario from './Formulario'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'

const Detalle = ({endPoint, expand, orden, idModelo, media, url}) => {
  const q = useQuery()
  const id = q.get('id')
  const editando = Boolean(id)
  const history = useHistory();

  const [request, setRequest] = useState({})

  const {
    model,
    modelLoading
  } = useModel(request)


  const breadcrumb = [
    {
      name: "Noticias",
      to: url,
      icon: <FileDoneOutlined/>,
    },
    {
      name: editando ? "Editar " + model?.estatus !== "BORRADOR" ? (model?.folio ? model?.folio : "---") : "Borrador" : "Agregar",
      to: `${url}/detalle`,
      icon: editando ? <EditOutlined/> : <PlusCircleOutlined/>,
    },
  ];


  const requestParams = useMemo(() => ({
    name: endPoint,
    id: id,
    ordenar: orden,
    expand: expand,
  }), [id])


  useEffect(() => {
    setRequest(requestParams)
    return () => {
      setRequest({})
    }
  }, [requestParams])

  const buttonData = [{
    text: "Volver",
    to: () => history.goBack(),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  }];

  return (
    <DefaultLayout
      breadcrumbItems={breadcrumb}
      multipleButtonData={buttonData}
      viewLoading={{
        text: 'Cargando ...',
        spinning: modelLoading
      }}
    >
      <Formulario
        id={id}
        url={url}
        model={model}
        idModelo={idModelo}
        expand={expand}
        endPoint={endPoint}
        editando={editando}
        media={media}
      />
    </DefaultLayout>
  )
}

export default Detalle