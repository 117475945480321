import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Divider,
} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import {SaveOutlined} from '@ant-design/icons'
import {AgregarMasivo} from '../../../components'
import TextArea from 'antd/lib/input/TextArea'
import dayjs from 'dayjs'
import {Respuestas} from '../../../utilities'
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse'

export const Formulario = ({
                             model = null,
                             id,
                             alTerminar,
                             endPoint,
                             url,
                             idModelo,
                             media,
                           }) => {
  const [form] = Form.useForm()
  const history = useHistory()

  const [guardando, setGuardando] = useState(false)
  const [diputados, setDiputados] = useState([])

  const handleFinish = async (values) => {
    try {
      setGuardando(true)

      let body = {
        ...values,
        diputados: diputados
      }

      if (id) {
        body.id = id
      }

      const res = await StatusResponse.post(`${endPoint}/guardar`, body)
      if (Respuestas(res)) {
        history.push(url);
      }

    } catch (e) {
      console.log(e)
    } finally {
      setGuardando(false)
    }
  }

  const encontrarMedia = useCallback((media) => {
    let _media = []
    const l = media?.length
    if (l > 0) {
      for (let i = 0; i < l; i++) {
        let archivo = media.find((a) => a?.id === media[i]?.id)
        archivo.url = `${archivo.ruta}`
        archivo.thumbUrl = `${archivo?.ruta}`
        archivo.name = archivo.nombre
        archivo.uid = archivo.uuid
        if (archivo) {
          _media.push(archivo)
        }
      }
    }

    return _media
  }, [])

  useEffect(() => {
    if (model || model !== null) {
      form.setFieldsValue({
        ...model,
        periodoInicio: model?.periodoInicio ? dayjs(model?.periodoInicio) : null,
        periodoFin: model?.periodoFin ? dayjs(model?.periodoFin) : null,
      })
      setDiputados(model.diputados)
      encontrarMedia(
        model[media]
          ?.filter((item) => item?.media !== null)
          ?.map((contenido) => contenido?.media)
      )
    } else {
      form.resetFields()
    }
  }, [form, media, model])

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      <Row gutter={[10, 20]} justify="end">
        <Col span={24}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: 'Por favor ingresa un nombre',
              },
            ]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Descripción" name="descripcion">
            <TextArea style={{resize: 'none', height: 80}}/>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label="Inicio" name="periodoInicio">
            <DatePicker style={{width: '100%'}}/>
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item label="Termino" name="periodoFin">
            <DatePicker style={{width: '100%'}}/>
          </Form.Item>
        </Col>
        <Divider/>
        <Col span={24}>
          <AgregarMasivo
            columns={[
              {
                title: "Nombre",
                dataIndex: "nombreCompleto",
                key: "nombreCompleto",
                width: "90%",
              },
            ]}
            asignados={diputados}
            setAsignados={setDiputados}
            selectModel={{
              name: 'usuario',
              ordenar: 'nombre',
              extraParams: {
                rol: 'DIPUTADO',
                legislatura: 'actual'
              }
            }}
            selectValue={'idUsuario'}
            selectLabel={'nombreCompleto'}
          />
        </Col>
        <Divider/>
        <Col span={24} md={6}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              icon={<SaveOutlined/>}
              loading={guardando}
            >
              Guardar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
export default Formulario