import React, {useMemo, useState} from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Button,
  Tooltip,
  Space,
} from "antd";
import {SearchOutlined, ClearOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {useModels} from "../../../hooks";

const {RangePicker} = DatePicker;

const SearchForm = ({btnGroup, formFiltros}) => {
  const [buscar, setBuscar] = useState("");
  const [inicio, setInicio] = useState(null);
  const [fin, setFin] = useState(null);
  const [limite, setLimite] = useState(10);
  const [form] = Form.useForm();

  const extraParamsMemo = useMemo(() => {
    let params = {}

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio.format('YYYY-MM-DD');
      params.fin = fin.format('YYYY-MM-DD');
    }

    return params

  }, [buscar, fin, inicio]);

  const onFinishButton = async values => {
    let params = {}
    const {buscar, rango, docTipo} = form.getFieldsValue(true);
    if (buscar && buscar.trim() !== '') {
      setBuscar(buscar.trim());
      params.buscar = buscar.trim();
    }
    if (rango && rango.length > 0) {
      setInicio(
        moment(rango[0].format('YYYY-MM-DD') + ' 00:00:00')
      );
      setFin(
        moment(rango[1].format('YYYY-MM-DD') + ' 23:59:59')
      );
      params.inicio = rango[0].format('YYYY-MM-DD') + ' 00:00:00';
      params.fin = rango[1].format('YYYY-MM-DD') + ' 23:59:59';
    }
    if (docTipo) {
      params.docTipo = docTipo;
    }
  }

  const history = useHistory();

  return (
    <Form
      form={form}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            label="Buscar: "
            name="buscar"
          >
            <Input
              placeholder="TITULO..."
              defaultValue={buscar}
              value={buscar}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            label="Rango de Compra:"
            name="rango"
          >
            <RangePicker
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              placeholder={['FECHA INICIO', 'FECHA LÍMITE']}
              style={{width: '100%'}}
              defaultValue={[inicio, fin]}
              value={[inicio, fin]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Space
            justify="space-between"
            style={{marginTop: '.5rem'}}
          >
            <Tooltip title="Buscar">
              <Button
                icon={<SearchOutlined/>}
                style={{marginTop: '1.5rem'}}
                shape="circle"
                type="primary"
                onClick={onFinishButton}
              />
            </Tooltip>
            <Tooltip title="Limpiar">
              <Button
                icon={<ClearOutlined/>}
                style={{marginTop: '1.5rem'}}
                shape="circle"
                type="default"
                onClick={() => {
                  form.resetFields();
                  setBuscar('');
                  setInicio(null);
                  setFin(null);
                }}
              />
            </Tooltip>
          </Space>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
          <Form.Item
            label="&nbsp;"
          >
            <Button
              block
              type="primary"
              icon={<PlusOutlined/>}
              onClick={() => history.push("/gestion-general/noticias/agregar")}
            >
              Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
};

export default SearchForm;
