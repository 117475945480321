import Detalle from "./Detalle";
import Listado from "./Listado";
import React from "react";
import {useHistory} from "react-router-dom";
import {Link} from "react-router-dom/cjs/react-router-dom.min";
import moment from 'moment'

const endPoint = 'legislatura'
const url = '/legislatura'
const expand = 'diputados'
const orden = 'descripcion-desc'
const idModelo = 'idLegislatura'
const media = 'mediaNoticia'


const Legislaturas = () => {
  const history = useHistory();

  const defaultText = (prop, item) =>
    prop ? (
      <a onClick={() => history.push(`${url}/detalle?id=${item[idModelo]}`)}>
        {prop}
      </a>
    ) : (
      <span style={{color: "#c7c3c3"}}>---</span>
    );

  const columnasTabla = [
    {
      title: 'Nombre',
      index: 'nombre',
      key: 'nombre',
      dataIndex: 'nombre',
      ellipsis: true,
      render: (item, row) => (
        <Link to={`${url}/detalle?id=${row[idModelo]}`} style={{color: 'black'}}>
          {item}
        </Link>
      ),
    },
    {
      title: 'Descripción',
      index: 'descripcion',
      key: 'descripcion',
      dataIndex: 'descripcion',
      ellipsis: true,
      render: (item, row) => (
        <Link to={`${url}/detalle?id=${row[idModelo]}`} style={{color: 'black'}}>
          {item}
        </Link>
      ),
    },
  ]

  return (
    <Listado
      endPoint={endPoint}
      expand={expand}
      url={url}
      orden={orden}
      columnas={columnasTabla}
      idModelo={idModelo}
    />
  );
};

const LegislaturaDetalle = () => {
  return (
    <Detalle
      endPoint={endPoint}
      expand={expand}
      url={url}
      orden={orden}
      idModelo={idModelo}
      media={media}
    />
  );
};
export {Legislaturas, LegislaturaDetalle};
