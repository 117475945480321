import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Row,
  Col,
  Button,
  Comment,
  Divider,
  Form,
  Input,
  List,
  message,
  Modal,
  notification,
  Select,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  UploadOutlined,
  FileDoneOutlined,
  PlusCircleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
import {useApp, useAuth, useModel, useModels, useQuery} from "../../hooks";
import {useHistory} from "react-router-dom";
import {DefaultLayout} from "../../components/layouts";
import StatusResponse from "../../services/statusResponse";
import {
  Anios,
  InformacionArchivos,
  meses,
  QuitarSignos,
  respuestas,
  Respuestas,
} from "../../utilities";
import {NumericFormat} from "react-number-format";
import {DatePicker} from "antd/es";
import {AiFillFilePdf} from "react-icons/ai";
import Upload from "../../components/Upload";
import {Select as SelectComponent} from '../../components'

const {REACT_APP_API_URL: baseUrl} = process.env;
const FondoLegislativoForm = () => {
  const modelName = "fondo-legislativo";

  const {TextArea} = Input;
  const {Title} = Typography;
  const [form, formConfirm] = Form.useForm();
  const history = useHistory();
  const q = useQuery();
  const id = q.get("id");
  const {user} = useAuth();
  const inputRef = useRef(null);
  const {token} = useApp();
  const editing = !!id;
  const {confirm} = Modal;

  const _baseUrl = baseUrl.replace("v1/", "");
  const [loading, setLoading] = useState(false);
  const [listaArchivos, setListaArchivos] = useState([]);
  const [infoArchivos, setInfoArchivos] = useState([]);

  const [requestFolio, setRequestFolio] = useState({});
  const [requestSolicitudes, setRequestSolicitudes] = useState({});

  const [comentario, setComentario] = useState("");
  const [comentarioList, setComentarioList] = useState([]);
  const [comentarios, setComentarios] = useState([]);
  const [solEstatus, setSolEstatus] = useState("");
  const [params, setParams] = useState({});
  const [ocultarGuardar, setOcultarGuardar] = useState(true);
  const [mensajeError, setMensajeError] = useState("");
  const [autToken, setAutToken] = useState("");
  const [visible, setVisible] = useState(false);
  const [solicitante, setSolicitante] = useState("");
  const [solicitadosMeses, setSolicitadosMeses] = useState([]);
  const [opcionMeses, setOpcionMeses] = useState([]);

  const requestSolicitudesParams = useMemo(() => ({
    name: modelName,
    extraParams: {
      ...params,
      idUsuario: params.idUsuario || user?.idUsuario,
    },
    expand:
      "receptores, remitentes, area, archivos, comentarios,creador, comentarios.usuario, solicitudes",
  }), [params, user]);

  const requestFolioParams = useMemo(() => ({
    name: "folio",
    extraParams: {
      legislatura: 63,
    },
  }), []);

  const {model} = useModel({
    name: modelName,
    id: id,
    expand:
      "receptores, remitentes, area, archivos, comentarios,creador, comentarios.usuario, solicitudes, solicitante",
  });

  const [folio] = useModels(requestFolio);

  const [solicitudes] = useModels(requestSolicitudes);

  const breadcrumb = [
    {
      name: "Gestión Legislativa",
      to: `/${modelName}`,

      icon: <FileDoneOutlined/>,
    },
    {
      name: editing ? "Editar " : "Agregar ",
      to: editing ? `/${modelName}/detalle?id=${id}` : `/${modelName}/detalle`,
      icon: editing ? <EditOutlined/> : <PlusCircleOutlined/>,
    },
  ];

  const comentar = async () => {
    let body = {};
    setComentario("");
    body.idFondoLegislativo = model?.idFondoLegislativo;
    body.comentario = comentario;
    body.idUsuario = user?.idUsuario;

    if (id) body.idFondoLegislativo = id;

    if (body.comentario === "") {
      message.error({
        content: "Comentario no puede estar vacio",
        style: {marginTop: "20vh"},
      });
      return;
    }

    try {
      setLoading(true);
      const res = await StatusResponse.post(
        `${modelName}/guardar-comentario`,
        body
      );
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (Respuestas(res)) {
          const arrComentarios = [...comentarioList];

          arrComentarios.push({
            author: user?.nombreCompleto,
            avatar: user?.urlFoto,
            content: <p>{comentario}</p>,
            datetime: (
              <Tooltip
                title={moment(res?.response?.detalle?.creado).format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              >
                <span>
                  {moment(res?.response?.detalle?.creado).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </span>
              </Tooltip>
            ),
          });
          setComentarioList(arrComentarios);
        }
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (info) => {
    let lista = info.fileList;
    for (let i = 0, l = lista?.length; i < l; i++) {
      lista[
        i
        ].url = `${_baseUrl}v1/descarga/documento?uuid=${lista[i]?.response?.detalle?.uuid}&access-token=${token}`;
    }
    setListaArchivos(info.fileList);
    InformacionArchivos(
      info?.file?.response?.detalle,
      infoArchivos,
      setInfoArchivos,
      "principal",
      token
    );
    Respuestas(info);
  };

  const onFinish = async (values, estatus) => {
    let body = {
      ...values,
    };

    body.archivos = infoArchivos;

    body.monto = QuitarSignos(body.monto);
    if (body.tipo === "FGL")
      if (body.subTipo === "COMPROBAR")
        if (body.monto > 200000) {
          notification.error({
            message: "Atención",
            description:
              "Al ser una comprobacion no se puede exceder el monto de $200,000.00.",
            placement: "bottomRight",
          });
          return;
        }

    if (body.tipo === "51")
      if (body.subTipo === "COMPROBAR")
        if (body.monto > 51000) {
          notification.error({
            message: "Atención",
            description:
              "Al ser una comprobacion no se puede exceder el monto de $51,000.00.",
            placement: "bottomRight",
          });
          return;
        }

    if (id) {
      body.idFondoLegislativo = id;
    } else {
      body.legislatura = 63;
    }

    if (estatus) {
      body.estatus = estatus;
    } else if (solEstatus) {
      body.estatus = solEstatus;
    }

    if (autToken) body.token = autToken;

    body.subTipo = body.subTipo.toString();

    try {
      setLoading(true);
      const res = await StatusResponse.post(`${modelName}/guardar`, body);
      if (Respuestas(res)) {
        respuestas(res);
        history.push(`/${modelName}`);
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishConfirm = async (values) => {
    let body = {...values, ...form.getFieldsValue()};

    if (!body.token) {
      console.log(autToken);
      Respuestas("error", "No se introdujo el TOKEN ");
      return;
    }
    if (id) body.idFondoLegislativo = id;

    body.subTipo = body.subTipo.toString();

    if (solEstatus) body.estatus = solEstatus;

    try {
      setLoading(true);
      const res = await StatusResponse.post(`${modelName}/guardar`, body);
      if (Respuestas(res)) {
        respuestas(res);
        // let data = await subirArchivos(
        //   res?.response?.detalle?.idFondoLegislativo
        // );
        // if (data) {
        //   message.success({
        //     content: data?.mensaje,
        //     style: { marginTop: "20vh" },
        //   });
        // }
        history.push(`/${modelName}`);
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    } finally {
      setLoading(false);
    }
  };

  const comprobarMes = () => {
    let {mes, anio, tipo, subTipo} = form.getFieldsValue();
    let usr = '';
    if (user.rol === "DIPUTADO")
      usr = user?.idUsuario;
    else
      usr = form.getFieldValue('idSolicitante')

    if (tipo === "FGL") {
      if (subTipo === "COMPROBAR") {
        form.setFieldValue("monto", 200000);
      }
    }

    if (tipo === "51") {
      if (subTipo === "COMPROBAR") {
        form.setFieldValue("monto", 51000);
      }
    }

    let _body = {};
    _body.idSolicitante = usr;

    /*if (mes)
      _body.mes = mes*/
    if (anio) _body.anio = anio;
    if (tipo) _body.tipo = tipo;

    setParams(_body);
  };

  const mostrar = () => {
    let _mostrar = false;

    if (user?.rol === "DIPUTADO" || user?.permisoExtra?.includes(6)) {
      if (ocultarGuardar) _mostrar = false;
      else if (!editing) _mostrar = true;
      else if (user?.idUsuario === model?.idCreador) _mostrar = true;
      else if (user?.idUsuario !== model?.idCreador) _mostrar = false;
    }

    return _mostrar;
  };

  const mostrarAprobar = () => {
    let _mostrar = false;

    if (user?.permisoExtra?.includes(155)) {
      if (editing) _mostrar = true;
      if (model?.tipo === "51") _mostrar = false;
    }

    if (user?.permisoExtra?.includes(156)) {
      if (editing) _mostrar = true;
      if (model?.tipo !== "51") _mostrar = false;
    }

    if (model?.estatus === "APROBADO") _mostrar = false;

    return _mostrar;
  };

  const onRemoveFile = async (file) => {
    let body = {idMedia: file?.uid};
    let _listaArchivos = listaArchivos;
    confirm({
      title: `¿Estás seguro de eliminar el Archivo ${file?.name}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: "Si, Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          const res = await StatusResponse.post(
            "fondo-legislativo/eliminar-archivo",
            body
          );
          if (Respuestas(res)) {
            _listaArchivos.filter(
              (item) => item?.idMedia !== res?.response?.detalle?.idMedia
            );
          }
        } catch (e) {
          console.log("Error al guardar: ", e);
        } finally {
        }
      },
      onCancel() {
        setListaArchivos(_listaArchivos);
      },
    });
    setListaArchivos(_listaArchivos);
  };

  const mostrarRechazar = () => {
    let _mostrar = false;

    if (user?.permisoExtra?.includes(155)) {
      if (editing) _mostrar = true;

      if (model?.tipo === "51") _mostrar = false;
    }

    if (user?.permisoExtra?.includes(156)) {
      if (editing) _mostrar = true;

      if (model?.tipo !== "51") _mostrar = false;
    }

    if (model?.estatus === "RECHAZADO") _mostrar = false;

    return _mostrar;
  };

  const passwordInput = useRef(null);

  useEffect(() => {
    setRequestFolio(requestFolioParams);
  }, [requestFolioParams]);

  useEffect(() => {
    setRequestSolicitudes(requestSolicitudesParams);
  }, [requestSolicitudesParams]);

  useEffect(() => {
    setComentarioList((oldState) => {
      const arrComentarios = [...oldState];
      let _comentarios = model?.comentarios;

      _comentarios?.map((comentario) => {
        arrComentarios.push({
          actions: [null],
          author:
            comentario?.usuario?.nombre +
            " " +
            comentario?.usuario?.apellidoPaterno +
            " " +
            comentario?.usuario?.apellidoMaterno,
          avatar: comentario?.usuario?.urlFoto,
          content: <p>{comentario?.comentario}</p>,
          datetime: (
            <Tooltip
              title={moment(comentario?.creado)
                .parseZone()
                .local()
                .format("DD/MM/YYYY HH:mm:ss")}
            >
              <span>
                {moment(comentario?.creado)
                  .parseZone()
                  .local()
                  .format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </Tooltip>
          ),
        });
        return null;
      });

      return arrComentarios;
    });

    if (model) {
      setSolicitante(model?.solicitante?.nombreCompleto);
    } else {
      if (user?.rol === 'DIPUTADO') {
        form.setFieldValue('idSolicitante', user?.idUsuario);
      }
    }
  }, [model]);

  useEffect(() => {
    if (formConfirm) {
      formConfirm.setFieldValue("token", null);
    }
  }, [visible, formConfirm]);

  useEffect(() => {
    if (inputRef.current)
      if (visible === true) {
        console.log("-", visible, inputRef);

        inputRef.current.focus({
          preventScroll: true,
          focusVisible: true,
        });
      } else inputRef.current.focus(null);
  }, [inputRef, visible]);

  useEffect(() => {
    if (
      comentarios?.length > 0 &&
      comentarios[0]?.idComentario !== undefined &&
      comentarios[0]?.idComentario !== null
    ) {
      let _comentarios = comentarios?.map((item) => {
        let usuario = model?.comentario?.usuario;
        return {
          actions: [<span key="comment-list-reply-to-0">Eliminar</span>],
          author:
            usuario?.nombre +
            " " +
            usuario?.apellidoPaterno +
            " " +
            usuario?.apellidoMaterno,
          avatar: usuario?.urlFoto,
          content: (
            <p>
              {item.comentario}{" "}
              {item.tipo === 0 ? (
                <span style={{color: "#1890ff", fontSize: "8px"}}>
                  Público
                </span>
              ) : (
                <span style={{color: "#ff0000", fontSize: "8px"}}>
                  Privado
                </span>
              )}
            </p>
          ),
          datetime: (
            <Tooltip
              title={moment(comentarios?.creado).format("DD/MM/YYYY HH:mm:ss")}
            >
              <span>
                {moment(comentarios?.creado).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </Tooltip>
          ),
        };
      });
      setComentarioList(_comentarios);
    }
  }, [comentarios, model]);

  useEffect(() => {
    if (passwordInput.current) {
      // or, if Input component in your ref, then use input property like:
      // passwordInput.current.input.focus();
      passwordInput.current.focus();
    }
  }, [passwordInput]);

  useEffect(() => {
    form.setFieldsValue(model);
    form.setFieldValue("creado", moment(model?.creado));
    form.setFieldValue("subTipo", model?.subTipo.split(","));
  }, [form, model]);

  useEffect(() => {
    setLoading(true);
    form.setFieldValue("anio", 2023);
    if (!editing) {
      const date = new Date();
      const _fo = folio[0];
      form.setFieldValue(
        "folio",
        "DGA-FGL-" +
        (parseInt(_fo?.FondoLegislativoSolicitud) + 1)
          .toString()
          .padStart(4, "0") +
        "/" +
        date.getFullYear()
      );
    }
    setLoading(false);
  }, [editing, folio, form]);

  useEffect(() => {
    let _meses = solicitudes?.map((i) => i.mes);
    setSolicitadosMeses(solicitudes);
    if (params.tipo === "51") {
      setOcultarGuardar(true);
      if (
        solicitudes?.filter(
          (solicitud) => solicitud.mes === form.getFieldValue("mes")
        ).length > 0
      ) {
        setOcultarGuardar(true);
        setMensajeError(
          <Tag color={"error"}>
            <MinusCircleOutlined/> Este apoyo fue solicitado este Mes
          </Tag>
        );
      } else {
        setOcultarGuardar(false);
        setMensajeError("");
      }
    } else if (params.tipo === "FGL") {
      setOcultarGuardar(true);
      if (
        solicitudes?.filter(
          (solicitud) => solicitud.mes === form.getFieldValue("mes")
        ).length > 0
      ) {
        setOcultarGuardar(true);
        setMensajeError(
          <Tag color={"error"}>
            <MinusCircleOutlined/> Este apoyo fue solicitado este Mes
          </Tag>
        );
      } else {
        setOcultarGuardar(false);
        setMensajeError("");
      }
    } else {
      setOcultarGuardar(false);
    }
  }, [solicitudes, params]);

  useEffect(() => {
    if (model?.archivos) {
      let _listaArchivos = model?.archivos
        ?.map((item) => {
          InformacionArchivos(
            item,
            infoArchivos,
            setInfoArchivos,
            "principal",
            token
          );
          return {
            name: item?.descripcion,
            uid: item?.uuid,
            url: `${_baseUrl}/v1/descarga/documento?uuid=${item?.uuid}&access-token=${token}`,
            status: "done",
            thumbUrl: `${item?.ruta}`,
          };
        })
        .filter(function (x) {
          return x !== undefined;
        });
      setListaArchivos(
        _listaArchivos && _listaArchivos.length > 0 ? _listaArchivos : []
      );
    }
  }, [model, token, _baseUrl]);

  useEffect(() => {
    let _meses = meses.map((item) => ({
      key: item.value,
      value: item.value,
      label:
        item.label +
        (solicitadosMeses.find((solicitado) => item.value === solicitado.mes)
          ? " (" +
          moment(
            solicitadosMeses.find(
              (solicitado) => item.value === solicitado.mes
            )?.creado
          ).format("dddd D MMMM YYYY HH:mm") +
          ")"
          : ""),
      disabled: solicitadosMeses.find(
        (solicitado) => item.value === solicitado.mes
      ),
    }));
    _meses.sort((a, b) => {
      const ordenarPorMes = a.value - b.value;
      if (a.disabled && !b.disabled) {
        return 1;
      } else if (!a.disabled && b.disabled) {
        return -1;
      } else {
        return ordenarPorMes !== 0
          ? ordenarPorMes
          : a.label.localeCompare(b.label);
      }
    });

    setOpcionMeses(_meses);
  }, [solicitadosMeses]);

  return (
    <DefaultLayout breadcrumbItems={breadcrumb}>
      <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
        <Row gutter={10} style={{textAlign: "left"}}>
          <Col span={4} offset={16} hidden={!editing}>
            <Button
              size="large"
              onClick={() =>
                window.open(
                  `${_baseUrl}/pdf/${modelName}/por-autorizar?id=${model.idFondoLegislativo}&access-token=${token}`,
                  "_blank"
                )
              }
              style={{backgroundColor: "#ad0b00", color: "#ffffff"}}
            >
              <AiFillFilePdf/>
              Imprimir Solicitud
            </Button>
          </Col>
          <Col span={4} hidden={model?.estatus !== "APROBADO"}>
            <Button
              size="large"
              onClick={() =>
                window.open(
                  `${_baseUrl}/pdf/${modelName}/autorizacion?id=${model.idFondoLegislativo}&access-token=${token}`,
                  "_blank"
                )
              }
              style={{backgroundColor: "#ad0b00", color: "#ffffff"}}
            >
              <AiFillFilePdf/>
              Imprimir Aprobación
            </Button>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="Folio" name="folio" hidden={!editing}>
              <Input
                disabled={!user?.permisoExtra?.includes(155)}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8, offset: 8}}
            lg={{span: 8, offset: 8}}
            xxl={{span: 8, offset: 8}}
          >
            <Form.Item label="Fecha" name="creado">
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{width: "100%"}}
                autocomplete="off"
                disabled
              />
            </Form.Item>
          </Col>
          {editing &&
          (user?.permisoExtra?.includes(156) ||
            user?.permisoExtra?.includes(155)) && <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Solicitante"
              rules={[{required: true, message: "Requerido"}]}
            >
              {solicitante}
            </Form.Item>
          </Col>}
          {user?.permisoExtra?.includes(6) && <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Solicitante"
              name={'idSolicitante'}
              rules={[{required: true, message: "Requerido"}]}
            >
              <SelectComponent
                style={{width: '100%'}}
                modelsParams={{
                  name: 'usuario',
                  ordenar: 'nombre',
                }}
                size="large"
                placeholder="Selecciona una opcion"
                labelProp={"nombreCompleto"}
                valueProp={'idUsuario'}
                allowClear={true}
                extraParams={{
                  rol: 'DIPUTADO',
                  legislatura: 'actual'
                }}
                autoComplete="off"
              />
            </Form.Item>
          </Col>}
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Concepto"
              name="tipo"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                autoComplete="off"
                onChange={() => comprobarMes()}
                size="large"
                onSelect={() => form.resetFields(["mes"])}
                options={[
                  {
                    value: "FGL",
                    label: "Asistencia Legislativa y Atención Ciudadana",
                  },
                  {value: "51", label: "Representación Legislativa"},
                ].map((item) => ({
                  key: item.value,
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Tipo de comprobación"
              name="subTipo"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                autoComplete="off"
                size="large"
                onChange={() => comprobarMes()}
                options={[
                  {value: "COMPROBAR", label: "Comprobar"},
                  {value: "REEMBOLSO", label: "Reembolso"},
                ].map((item) => ({
                  key: item.value,
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Monto"
              name="monto"
              rules={[{required: true, message: "Requerido"}]}
            >
              <NumericFormat
                autoComplete="off"
                thousandSeparator=","
                className={"large-numeric"}
                prefix={"$"}
                decimalScale={2}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Mes"
              name="mes"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                size="large"
                autoComplete="off"
                style={{width: "100%"}}
                placeholder="Seleccione un Mes"
                onChange={() => comprobarMes()}
                options={opcionMeses}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Año"
              name="anio"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                autoComplete="off"
                size="large"
                style={{width: "100%"}}
                placeholder="Seleccione un Año"
                onChange={() => comprobarMes()}
                options={Anios().map((item) => ({
                  key: item.value,
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item label="Descripción" name="descripcion">
              <TextArea placeholder="" autoSize={{minRows: 4, maxRows: 20}}/>
            </Form.Item>
          </Col>
        </Row>
        <Divider/>
        <Row gutter={10} hidden={!editing}>
          <Col className="gutter-row" span={24}>
            <List
              className="comment-list"
              header={<strong>Seguimiento</strong>}
              itemLayout="horizontal"
              dataSource={comentarioList}
              locale={{emptyText: "No hay seguimiento"}}
              renderItem={(item) => (
                <li>
                  <Comment
                    author={item.author}
                    avatar={item.avatar}
                    content={item.content}
                    datetime={item.datetime}
                  />
                </li>
              )}
            />
          </Col>
          <Col className="gutter-row" span={20}>
            <TextArea
              placeholder="Introduce aqui tu comentario"
              size="large"
              onChange={(e) => {
                setComentario(e.target.value);
              }}
              value={comentario}
              autoSize={{minRows: 3, maxRows: 20}}
            />
          </Col>
          <Col className="gutter-row" span={4}>
            <Button
              type="primary"
              block
              size="large"
              onClick={() => comentar(0)}
            >
              Añadir seguimiento
            </Button>
          </Col>
          <Divider/>
        </Row>
        {/** Anexos  */}
        <Row gutter={10}>
          <Col className="gutter-row" span={18}>
            <Title level={5}>Anexos</Title>
          </Col>
          <Col className="gutter-row" span={24}>
            <Upload
              onChange={onChange}
              fileList={listaArchivos}
              listType="picture-card"
              onRemove={(file) => onRemoveFile(file)}
              className={"button-upload"}
              text={
                <>
                  <UploadOutlined/> Subir archivos
                </>
              }
            />
          </Col>
          <Divider/>
        </Row>
        {/* Botones */}
        <Row gutter={10}>
          <Col
            span={24}
            style={{textAlign: "center"}}
            hidden={mensajeError === ""}
          >
            {mensajeError}
          </Col>
          <Col span={6} hidden={!mostrar()}>
            <Form.Item>
              <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                loading={loading}
                onClick={() => setSolEstatus("NUEVO")}
                icon={<SaveOutlined/>}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
          <Col span={6} hidden={!mostrarAprobar()}>
            <Button
              type="primary"
              block
              size="large"
              onClick={() => {
                setSolEstatus("APROBADO");
                setVisible(true);
                //onFinishConfirm(form.getFieldsValue(), "APROBADO")
              }}
              loading={loading}
              icon={<CheckCircleOutlined/>}
              style={{background: "#4caf50", border: "none"}}
            >
              Aprobar
            </Button>
          </Col>
          <Col span={6} hidden={!mostrarRechazar()}>
            <Form.Item>
              <Button
                type="primary"
                block
                size="large"
                danger
                onClick={() => {
                  setSolEstatus("RECHAZADO");
                  setVisible(true);
                  //onFinishConfirm(form.getFieldsValue(), "RECHAZADO")
                }}
                loading={loading}
                icon={<CloseCircleOutlined/>}
              >
                Rechazar
              </Button>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button
              type="dashed"
              block
              size="large"
              onClick={() => history.goBack()}
            >
              Salir
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal title="TOKEN" centered open={visible} width={600} footer={null}>
        <Form
          form={formConfirm}
          name="form"
          autoComplete="on"
          layout="vertical"
          onFinish={onFinishConfirm}
        >
          {/* Botones */}
          <Row gutter={10}>
            <Col span={24} style={{textAlign: "center"}}>
              <Form.Item name="token">
                <Input
                  type="password"
                  autoComplete={"one-time-code"}
                  ref={inputRef}
                  maxLength={6}
                  style={{
                    fontSize: "40px",
                    letterSpacing: "16px",
                    textAlign: "center",
                  }}
                />
              </Form.Item>
            </Col>
            {solEstatus === "APROBADO" ? (
              <Col offset={12} span={6} hidden={!mostrarAprobar()}>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    size="large"
                    htmlType="submit"
                    loading={loading}
                    icon={<CheckCircleOutlined/>}
                    style={{background: "#4caf50", border: "none"}}
                  >
                    Aprobar
                  </Button>
                </Form.Item>
              </Col>
            ) : (
              <Col offset={12} span={6} hidden={!mostrarRechazar()}>
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    size="large"
                    danger
                    htmlType="submit"
                    loading={loading}
                    icon={<CloseCircleOutlined/>}
                  >
                    Rechazar
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col span={6} hidden={!mostrarRechazar()}>
              <Form.Item>
                <Button
                  type="dasher"
                  block
                  size="large"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </DefaultLayout>
  );
};

export default FondoLegislativoForm;
