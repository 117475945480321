import React from 'react'
import PropTypes from "prop-types";
import {Select as AntdSelect, Tag, Divider, Collapse} from 'antd'
import {useModels} from '../hooks';
import {AgregarFaltantes} from '../utilities';
import {PlusOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

const Select = ({
                  modelsParams,
                  labelProp,
                  valueProp,
                  render,
                  append,
                  deleteSelected,
                  extraParams,
                  onChange,
                  labelInValue = false,
                  filtered = [],
                  formulario,
                  optionSelected,
                  ocultar = false,
                  ...props
                }) => {

  const [request, setRequest] = React.useState({});
  const [buscarValue, setBuscarValue] = React.useState('');
  const [timer, setTimer] = React.useState(null);
  const [selected, setSelected] = React.useState([...filtered]);

  const extraParamsMemo = React.useMemo(() => ({buscar: buscarValue, ...extraParams || {}}),
    [buscarValue, extraParams]
  );

  const requestMemo = React.useMemo(() => ({
    name: modelsParams?.name || "",
    ordenar: modelsParams?.ordenar || `${valueProp || 'id'}-desc`,
    limite: modelsParams?.limite || 20,
    expand: modelsParams?.expand || "",
    extraParams: extraParamsMemo,
  }), [extraParamsMemo, modelsParams?.expand, modelsParams?.limite, modelsParams?.name, modelsParams?.ordenar, valueProp]);

  const [
    modelsData,
    modelsDataLoading,
    modelsError
  ] = useModels(request);

  const onSearch = (value) => {
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      setBuscarValue(value);
    }, 300);

    setTimer(newTimer);
  };

  if (!render) {
    render = (value) => value;
  }

  if (!append) {
    append = [];
  }

  const _onChange = (value, option) => {
    if (props?.mode && props?.mode === 'multiple') {
      setSelected(ultimoEstado => AgregarFaltantes([...ultimoEstado], [...option], valueProp));
    } else {
      setSelected(ultimoEstado => AgregarFaltantes(ultimoEstado, option, valueProp));
    }
    onChange && onChange(value, option);
  }

  const options = React.useMemo(() => {
    let aux = AgregarFaltantes([...modelsData], [...append], valueProp);
    // aux = aux.filter(item => !selected.find(i => item[valueProp] === i[valueProp]));
    return aux || [];
  }, [append, modelsData, valueProp]);

  const Formulario = () => {
    <></>
  }

  React.useEffect(() => {
    setRequest(requestMemo);
    return () => {
      setRequest({});
    };
  }, [requestMemo]);

  if (modelsError) {
    return <Tag color='red'>error al obtener información de selector.</Tag>
  }

  return (
    <AntdSelect
      {...props}
      labelInValue={labelInValue}
      showSearch
      allowClear={true}
      filterOption={false}
      onSearch={onSearch}
      defaultActiveFirstOption={false}
      //notFoundContent={null}
      defaultValue={optionSelected}
      style={{width: '100%'}}
      loading={modelsDataLoading}
      onChange={_onChange}
      options={options.map(i => ({
        ...i,
        label: render(i[labelProp], i),
        value: i[valueProp],
      }))}
      onDeselect={(v) => {
        if (!ocultar) {
          setSelected(ls => ls.filter(i => i.id !== v.value))
        }
      }}
      dropdownRender={(optionsm) => (
        formulario ? <> {optionsm}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Collapse
            bordered={false}
            ghost
            expandIcon=""
            expandIconPosition={'end'}
            className="site-collapse-custom-collapse"
          >
            <Panel key="1" extra={<><PlusOutlined/> Agregar </>}>
              {formulario}
            </Panel>
          </Collapse>
        </> : <>{optionsm}</>
      )}
    />
  )
}

Select.propTypes = {
  modelsParams: PropTypes.object.isRequired,
  labelProp: PropTypes.string.isRequired,
  valueProp: PropTypes.string.isRequired,
  render: PropTypes.func,
  notIn: PropTypes.string,
  onDeselected: PropTypes.func,
  deleteSelected: PropTypes.string,
  filtered: PropTypes.array,
};

export default Select