import {Select as SelectComponent} from '../components'
import React, {useState} from 'react'
import {Table, Row, Col, Button} from 'antd/es'
import {BiTrash} from 'react-icons/bi'

const AgregarMasivo = ({
                         asignados,
                         setAsignados,
                         columns,
                         selectModel,
                         selectValue,
                         selectLabel
                       }) => {
  const [row, setRow] = useState({})

  const handleChange = (selected) => {
    let _arrayAux = [...asignados]
    if (selected !== '' && selected !== undefined && selected[selectValue] !== undefined) {
      _arrayAux.push(selected)
      setAsignados(_arrayAux)
    }
  }

  const columnas = [
    {
      width: '5%',
      title: '',
      render: (_, item) => (
        <Button
          danger
          icon={<BiTrash/>}
          onClick={() => {
            let _arrayAux = asignados.filter((_item) => _item.id !== item.id)
            setAsignados(_arrayAux)
          }}
        >
          Eliminar
        </Button>
      ),
    },
    ...columns,
  ]

  return (<Row gutter={[12, 12]}>
      <Col span={20}>
        <SelectComponent
          style={{width: '100%'}}
          modelsParams={selectModel}
          placeholder="Selecciona una opcion"
          labelProp={selectLabel}
          valueProp={selectValue}
          allowClear={true}
          autoComplete="off"
          notIn={asignados.map((_item) => _item.id).join(',')}
          value={row}
          onSelect={(item, row) => setRow(row)}
        />
      </Col>
      <Col span={4}>
        <Button
          type="primary"
          onClick={() => {
            handleChange(row)
            setRow({})
          }}
        >
          Agregar
        </Button>
      </Col>
      <Col span={24}>
        <Table
          columns={columnas}
          rowKey={(record) => record[selectValue]}
          dataSource={asignados}
        />
      </Col>
    </Row>
  )
}

export default AgregarMasivo