import React, {useMemo, useState,} from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import {Modal, Tooltip} from "antd";
import {useHistory} from "react-router-dom";
import SimpleTableLayout from "../../../components/layouts/SimpleTableLayout";
import ActionsButton from "../../../components/actionsbutton/ActionsButton";
import {Tabla as TableComponent} from "../../../components";
import SearchForm from "./SearchForm";
import {Form} from "antd";
import StatusResponse from "../../../services/statusResponse";

const {REACT_APP_API_URL: baseUrl} = process.env;

const Listado = ({
                   titulo,
                   orden,
                   modelo,
                   idModelo,
                   endPoint,
                   breadcrumbItems,
                   idMedia,
                   onFinishButton,
                   columnas,
                   row,
                   expand = ""
                 }) => {

  const tablaRef = React.useRef();
  const history = useHistory();
  const [formFiltros] = Form.useForm();

  const url = '/gestion-general/legislatura';

  const _baseUrl = baseUrl.replace("v1/", "")
  const [buscarParams, setBuscarParams] = useState({})
  const [buscar, setBuscar] = useState(undefined);
  const [inicio, setInicio] = useState(undefined);
  const [fin, setFin] = useState(undefined);

  const extraParamsMemo = useMemo(() => {
    let params = {}

    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio.format('YYYY-MM-DD');
      params.fin = fin.format('YYYY-MM-DD');
    }

    return params

  }, [buscar, fin, inicio]);


  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{color: "red"}}/>,
      content: `¿Desea eliminar el área "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {
        try {
          const res = await StatusResponse.delete("noticia-sidice/eliminar", {id: valor.id});
          if (res && res.status === 200) {
            tablaRef.current.refresh()
            return Promise.resolve();
          } else if (res?.status === 400) {

            Modal.error({
              title: "Error: no se logró eliminar la noticia",
              content: res?.mensaje,
            });
            return Promise.reject();
          }
        } catch (error) {

          console.error("Error al eliminar la noticia:", error);
          Modal.error({
            title: "Error al eliminar la noticia",
            content: "Ocurrió un error durante la solicitud.",
          });
          return Promise.reject();
        }
      },
    });
  };

  const btnGroup = [
    {
      id: 2,
      onClick: () => {
        setBuscarParams({});
        formFiltros.resetFields();
      },
      props: {disabled: false, type: "dashed", block: true},
      icon: (
        <Tooltip title={"Limpiar"}>
          <ClearOutlined/>
        </Tooltip>
      ),
    },
    {
      id: 3,
      onClick: () => history.push(`${url}/detalle`),
      props: {disabled: false, type: "primary", block: true},
      icon: (
        <Tooltip title={"Agregar"}>
          <PlusOutlined/>
        </Tooltip>
      ),
    },
  ];


  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/detalle?id=${item[idModelo]}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    ...columnas
  ];


  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      withSearchButton={false}
      children={
        <>
          <SearchForm
            formFiltros={formFiltros}
            btnGroup={{
              btnGroup,
              flex: {justifyContent: "start", flexDirection: "row"},
              /*  onSearch: onSearch, */
            }}
            searchPlaceholder="Buscar..."
            /*  onSearchClicked={onSearch} */
          />
          <br/>
          <TableComponent
            nameURL={endPoint}
            columns={columns}
            rowKey={idMedia}
            innerRef={tablaRef}
            order={orden}
            expand={expand}
            extraParams={extraParamsMemo}
          />
        </>
      }
    />
  );
};

export default Listado